const DOM = {
    // DOM elements
    "html": document.querySelector('html'),
    get header() {
        return this.html.querySelector('.top-navigation');
    },
    get footer() {
        return this.html.querySelector('.bottom-navigation');
    },
    get mobileToggler() {
      return this.header.querySelector('.top-navigation__mobile-toggler');
    },
    // Loader and his inner elements
    get loader() {
        return this.html.querySelector('.welcome__motif');
    },
    // dependecies elements
    get glide() {
        return this.html.querySelectorAll('.glide')
    },
};

const pages = {
    "home": DOM.html.querySelector('.main-home')
};

const platform = {
    "mobile": {
        "iOS": navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
        "android": navigator.userAgent.match(/Android/i)
    }
};

let globals = {
    "isLoaded": false,
    "userAgent": navigator.userAgent,
    "hasTouchScreen": () => {
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
        if ("maxTouchPoints" in navigator) {
            return navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
            return navigator.msMaxTouchPoints > 0;
        } else {
            const mQ = window.matchMedia && matchMedia("(pointer:coarse)");

            if (mQ && mQ.media === "(pointer:coarse)") {
                return !!mQ.matches;
            } else if ('orientation' in window) {
                return true; // deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                return (
                    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(globals.userAgent) ||
                    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(globals.userAgent)
                );
            }
        }
    }
}

export {DOM, globals, pages, platform}
