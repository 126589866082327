import {requestAnimationFrame, cancelAnimationFrame} from 'animation-frame-polyfill';
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {DOM, globals} from "./_variables";

gsap.registerPlugin(ScrollTrigger);

export default {
    revealItemsOnScroll: () => {
        ScrollTrigger.batch('.reveal', {
            onEnter: batch => gsap.to(
                batch, {
                    y: 0,
                    opacity: 1,
                    /*stagger: {each: 0.1, grid: [1, 3]},
                    overwrite: true,*/
                    duration: 1.5,
                    ease: "circ.out"
                }),
        });
    },
    mobileMenu: () => {
        DOM.mobileToggler.addEventListener('click', () => {
            DOM.header.classList.toggle('top-navigation--opened');
        });
    },
    videoOverlay: () => {
        const triggers = document.querySelectorAll('video[data-video-overlay]');

        if (triggers.length) {
            const videoOverlay = document.querySelector('.video-overlay');
            const videoOverlayInnerVideo = videoOverlay.querySelector('video');
            const videoOverlayLayer = videoOverlay.querySelector('.video-overlay__layer');
            let isPlaying = false;

            const setVideoUrl = (src) => {
                videoOverlayInnerVideo.src = src;

                gsap.to(videoOverlay, .7, {
                    onStart: function () {
                        playVideo();
                    },
                    autoAlpha: 1
                });
            }

            const playVideo = () => {
                isPlaying = true;
                videoOverlayInnerVideo.muted = false;
                videoOverlayInnerVideo.play();
                videoOverlayInnerVideo.classList.add('video-overlay__video--playing');
            }

            const pauseVideo = () => {
                videoOverlayInnerVideo.pause();
                videoOverlayInnerVideo.muted = true;
                videoOverlayInnerVideo.classList.remove('video-overlay__video--playing');
                isPlaying = false;
            }

            for (const trigger of triggers) {
                const source = trigger.getAttribute('data-video-overlay');

                trigger.addEventListener('click', () => {
                    setVideoUrl(source);
                });

                videoOverlayLayer.addEventListener('click', function () {
                    gsap.to(videoOverlay, .7, {
                        onStart: () => {
                            pauseVideo();
                            videoOverlayInnerVideo.currentTime = 0
                        },
                        autoAlpha: 0
                    });
                });
            }

            videoOverlayInnerVideo.addEventListener('click', () => {
                if (isPlaying) {
                    pauseVideo();
                } else {
                    playVideo();
                }
            });
        }
    },
    clickVideo: () => {
        const triggers = document.querySelectorAll('.click-video');

        if (triggers.length) {
            for (const trigger of triggers) {
                const videoPreview = trigger.querySelector('.click-video__preview');
                const videoFull = trigger.querySelector('.click-video__full');
                let isPlaying = false;

                trigger.addEventListener('click', () => {
                    if (!isPlaying) {
                        gsap.to(videoPreview, {
                            autoAlpha: 0,
                            duration: .5
                        });

                        gsap.to(videoFull, {
                            onStart: () => {
                                videoFull.load();
                                videoFull.addEventListener('loadeddata', (e) => {
                                    videoFull.play();
                                });
                            },
                            autoAlpha: 1,
                            duration: .5,
                            onComplete: () => {
                                videoPreview.pause();
                                isPlaying = true;
                            }
                        });
                    } else {
                        gsap.to(videoFull, {
                            onStart: () => {
                                videoFull.pause();
                                videoFull.currentTime = 0;
                            },
                            autoAlpha: 0,
                            duration: .5,
                            onComplete: () => {
                                videoPreview.play();
                                isPlaying = false;
                            }
                        });

                        gsap.to(videoPreview, {
                            autoAlpha: 1,
                            duration: .5
                        });
                    }
                });
            }
        }
    },
    retailersFinder: () => {
        const form = document.querySelector('.main-retailers form');
        const results = document.querySelectorAll('.retailers-result');

        if (form) {
            const button = form.querySelector('button');
            const country = form.querySelector('.select-country');
            const city = form.querySelector('.select-city')

            const returnRetailersByCountry = () => {
                let cities = [];

                for (const result of results) {
                    const value = result.getAttribute('data-country');

                    if (value === country.value) {
                        const relatedCity = result.getAttribute('data-city');

                        if (!cities.includes(relatedCity)) {
                            cities.push(relatedCity);
                        }
                    }
                }

                for (const cityValue of cities) {
                    const option = document.createElement('option');
                    const optionText = document.createTextNode(cityValue);

                    option.appendChild(optionText);
                    option.setAttribute('value', cityValue);

                    city.appendChild(option)
                }
            }

            const showResults = () => {
                gsap.to('.retailers-result', 0, {
                    display: 'none'
                });

                gsap.to('.retailers-result[data-country="' + country.value + '"][data-city="' + city.value + '"]', 0, {
                    display: 'flex'
                });
            }

            country.addEventListener('change', () => {
                const options = city.querySelectorAll('option');

                for (const option of options) {
                    option.remove();
                }

                returnRetailersByCountry();
            });

            button.addEventListener('click', (event) => {
                event.preventDefault();
                showResults();
            });

            returnRetailersByCountry();
        }
    },
    inspirationColumn: () => {
        const column = document.querySelector('.scrolling-column');

        if (column) {
            const elements = document.querySelectorAll('.inspiration-grid__images');

            let footerVisible = false;

            // First we select the element we want to target
            const target = DOM.footer;

            // Next we want to create a function that will be called when that element is intersected
            function handleIntersection(entries) {
                // The callback will return an array of entries, even if you are only observing a single item
                entries.map((entry) => {
                    if (entry.isIntersecting) {
                        footerVisible = true;
                    } else {
                        footerVisible = false;
                    }
                });
            }

            // Next we instantiate the observer with the function we created above. This takes an optional configuration
            // object that we will use in the other examples.
            const observer = new IntersectionObserver(handleIntersection);

            // Finally start observing the target element
            observer.observe(target);

            const scrollColumn = () => {

                if (!footerVisible) {
                    gsap.to(column, 0, {
                        transform: 'translateY(calc(-' + (elements.length - 1) * 100 + '% + ' + window.scrollY + 'px))'
                    });
                }

                window.requestAnimationFrame(scrollColumn)
            }

            scrollColumn();
        }
    }
}