'use strict';

require('intersection-observer');
import global from './_global';
import lazyLoad from './_lazy-load';
import animation from './_animations'

document.addEventListener("DOMContentLoaded", () => {
    global.pageLoadedChecker();
    global.getSreenSize();
    animation.mobileMenu();
    animation.revealItemsOnScroll();
    animation.videoOverlay();
    animation.clickVideo();
    animation.retailersFinder();
    animation.inspirationColumn();

    // plugins
    lazyLoad.init();
});
